





















































import './WorkList.scss';
import './WorkListAdaptive.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IConstruct, IConstructDocument, IWork, IWorkListState } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListActions, WorkListMutations } from '@store/modules/work-list/Types';
import { ITransferPostData } from '@store/modules/work-list/interfaces/ApiRequests';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { UserActionTypes } from '@store/modules/user/Types';
import { IUser } from '@store/modules/user/Interfaces';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'WorkList',

    components: {
        BasePreloader: () => import('@components/BasePreloader'),
        WorkComposite: () => import('./components/WorkComposite.vue'),
        WorkListHeader: () => import('./components/WorkListHeader.vue'),
        ConstructPacks: () => import('./components/ConstructPacks.vue'),
        WorkConstructs: () => import('./components/WorkConstructs.vue'),
        WorkAddingPopup: () => import('./components/WorkAddingPopup.vue'),
        ExecutiveDocumentAddingPopup: () => import('./components/ExecutiveDocumentAddingPopup.vue'),
        DeleteWorkPopup: () => import('./components/DeleteWorkPopup.vue'),
        DocumentFilesPopup: () => import('./components/DocumentFilesPopup.vue'),
        ConstructMovingPopup: () => import('./components/ConstructMovingPopup.vue'),
        PopupConfirmation: () => import('@/components/popups/PopupConfirmation.vue'),
        BaseListItemShell: () => import('@components/BaseListItemShell/BaseListItemShell.vue'),
        ConstructDocumentationPopup: () => import('./components/ConstructDocumentationPopup.vue'),
    },
})
export default class WorkList extends Vue {
    $refs!: {
        workListHead: HTMLFormElement,
    }

    @NSProject.Getter('projectId') projectId!: number;

    @NSWorkList.State((state: IWorkListState) => state.viewingWorkTitle) viewingWorkTitle!: string;
    @NSWorkList.State((state: IWorkListState) => state.viewingPack) viewingPack!: IConstruct[];
    @NSWorkList.State((state: IWorkListState) => state.viewingPackTitle) viewingPackTitle!: IConstruct[];
    @NSWorkList.Getter('workListLoaded') workListLoaded!: boolean;
    @NSWorkList.Getter('constructsGrouping') constructsGrouping!: 'composite' | 'packs';
    @NSWorkList.Getter('actionOnConstruct') actionOnConstruct!: IConstructDocument;
    @NSWorkList.Getter('selectedViewingConstructs') selectedWorksStore!: IConstructDocument[];
    @NSWorkList.Action(WorkListActions.A_GET_WORK_LIST) getWorkList!: (projectId: number) => Promise<void>;
    @NSWorkList.Mutation(WorkListMutations.M_SET_WORK_LIST_LOADED) setWorkListLoaded!: (loadedState: boolean) => void;
    @NSWorkList.Getter('filters') filters!: any[];
    @NSWorkList.Getter('filtersApply') filtersApply!: boolean;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_PACK_PD_OPTIONS) getPackPDList!: (id: number) => void;
    @NSWorkList.Getter('workList') workList!: IWork[];
    @NSWorkList.Getter('viewingWork') viewingWork!: IWork;
    @NSWorkList.Getter('pathToViewingWork') pathToViewingWork!: Set<IWork>;
    @NSWorkList.Mutation('M_SET_VIEWING_WORK_TITLE') setViewingWorkTitle!: (work: IWork) => void;
    @NSWorkList.Action(WorkListActions.A_TRANSFER_CONSTRUCTS)
    transferConstructs!: (
        payload: ITransferPostData,
    ) => Promise<void>
    @NSWorkList.Action('A_PROJECT_STORE_LISTENER') reloadWorks!: (id: string | number) => Promise<void>;
    @NSWorkList.Action('A_SET_VIEWING_WORK') setViewingWork!: (id: string | number) => Promise<void>;
    @NSWorkList.Action(WorkListActions.A_SET_PATH_TO_VIEWING_WORK) setPathToViewingWork!: (payload: Set<IWork>) => Promise<void>;
    @NSUser.Action(UserActionTypes.A_REFRESH_TOKEN) refreshToken!: () => Promise<IUser>

    informationPopupShow: boolean = false;
    informationPopupText: string = '';
    showInformationPopupDuration: number = 2500;

    confirmMovingToFolderShow: boolean = false;
    confirmMovingToFolderText: string = '';
    documentsToFolder: string[] = [];
    folderForDocumentsMove: string = '';
    folderForDocumentsMoveTitle: string = '';
    typeOdItemsMoveToFolder: string = '';

    mounted() {
        this.onMounted(true);
    }

    @Watch('$route.params.projectId')
    watchProjectId() {
        this.setWorkListLoaded(false);
    }
    @Watch('workListLoaded')
    watchWorkListLoaded() {
        if (this.workListLoaded) {
            this.getPackPDList(parseInt(this.$route.params.projectId, 10));
            this.setViewingWork(this.viewingWork.uuid);
            this.setPathToViewingWork(this.pathToViewingWork);
        }
    }

    get currentViewTitle() {
        switch (this.constructsGrouping) {
            case 'composite':
                return 'Иерархия работ';
            case 'packs':
                return 'Комплекты'
        }
    }

    get constructsToDisplay() {
        switch (this.constructsGrouping) {
            case 'composite':
                return this.viewingWork.constructs;
            case 'packs':
                return this.viewingPack;
        }
    }

    get filteredConstructsToDisplay() {
        if (this.filtersApply) {
            let output: any[] = this.constructsToDisplay;
            this.filters.forEach((filterObject) => {
                if (filterObject.type === 'checkboxes') {
                    if (filterObject.key === 'construct.plan') {
                        output = output.filter(work => work.plan !== work.fact);
                    }
                }
                if (filterObject.type === 'select') {
                    if (filterObject.key === 'status.title') {
                        output = output.filter(
                            doc => doc.status ? filterObject.selected.includes(doc.status.title) : false,
                            doc => !doc.isFolder,
                        );
                    }
                }
                if (filterObject.type === 'dateRange' && (filterObject.datesRange.dateFrom || filterObject.datesRange.dateTo)) {
                    output = output.filter(
                        doc => filterObject.datesRange.dateFrom ? Date.parse(doc[filterObject.key]) >= Date.parse(filterObject.datesRange.dateFrom) : true,
                        doc => !doc.isFolder,
                    );
                    output = output.filter(
                        doc => filterObject.datesRange.dateTo ? Date.parse(doc[filterObject.key]) <= Date.parse(filterObject.datesRange.dateTo) : true,
                        doc => !doc.isFolder,
                    );
                }
            });
            return output;
        }
        return this.constructsToDisplay;
    }

    get titleToDisplay() {
        switch (this.constructsGrouping) {
            case 'composite':
                return this.viewingWorkTitle;
            case 'packs':
                return this.viewingPackTitle;
        }
    }

    openInformationPopup(text: string) {
        this.informationPopupText = text;
        this.informationPopupShow = true;

        setTimeout(() => {
            this.informationPopupText = '';
        }, this.showInformationPopupDuration);
    }

    onMounted(tryRefreshToken = false) {
        this.getWorkList(parseInt(this.$route.params.projectId, 10)).finally(() => {
            this.setWorkListLoaded(true);
        }).catch(err => {
            if (tryRefreshToken && err.response?.status === 401) {
                this.refreshToken().then(() => {
                    this.onMounted();
                });
            }
        });
    }

    showPopupConfirmAddItemsToFolder(e) {
        this.typeOdItemsMoveToFolder = e.elementType;
        if (this.typeOdItemsMoveToFolder && this.typeOdItemsMoveToFolder === 'work') {
            let itemsCount: number = 1;
            let itemsForMove: any[] = [e.dropElementId];
            if (this.selectedWorksStore && this.selectedWorksStore.length) {
                itemsCount = this.selectedWorksStore.length;
                itemsForMove = this.selectedWorksStore.map(item => item.uuid);
            }
            this.confirmMovingToFolderText = `Переместить работы (${itemsCount} шт.) в папку "${e.dropTargetTitle}"?`;
            this.confirmMovingToFolderShow = true;
            this.documentsToFolder = itemsForMove;
            this.folderForDocumentsMove = e.dropTargetId;
            this.folderForDocumentsMoveTitle = e.dropTargetTitle;
        } else if (this.typeOdItemsMoveToFolder
            && this.typeOdItemsMoveToFolder === 'composite'
            && e.dropTargetId !== e.dropElementId) {
            this.confirmMovingToFolderText = `Переместить выбранную папку в папку "${e.dropTargetTitle}"?`;
            this.confirmMovingToFolderShow = true;
            this.documentsToFolder = [e.dropElementId];
            this.folderForDocumentsMove = e.dropTargetId;
            this.folderForDocumentsMoveTitle = e.dropTargetTitle;
        }
    }

    confirmAddDocsToFolder() {
        this.transferConstructs({
            appId: this.projectId,
            constructIds: this.documentsToFolder,
            structureId: this.folderForDocumentsMove,
        }).then(() => {
            if (this.typeOdItemsMoveToFolder && this.typeOdItemsMoveToFolder === 'work') {
                this.showInformationPopup(
                    `Работы (${this.documentsToFolder.length} шт.) успешно перемещены в папку "${this.folderForDocumentsMoveTitle}"`,
                );
            }

            this.reloadWorkList();
        });
    }

    resetFilters() {
        this.$refs.workListHead.resetFilters();
    }

    confirmConstructMoving(countSelectedConstruct) {
        let confirmConstructMovingText = 'Конструктив успешно перемещен';

        if (countSelectedConstruct > 1) {
            confirmConstructMovingText = `Конструктивы успешно перемещены: ${countSelectedConstruct} шт.`;
        }

        this.showInformationPopup(confirmConstructMovingText);
        this.reloadWorkList();
    }

    reloadWorkList(): void {
        setTimeout(() => {
            this.setWorkListLoaded(false);
            this.confirmMovingToFolderShow = false;
            this.documentsToFolder = [];
            this.folderForDocumentsMove = '';
            this.folderForDocumentsMoveTitle = '';
            this.typeOdItemsMoveToFolder = '';
            this.reloadWorks(this.projectId);
        }, this.showInformationPopupDuration);
    }

    showInformationPopup(text) {
        this.informationPopupShow = true;
        this.informationPopupText = text;

        setTimeout(() => {
            this.informationPopupShow = false;
            this.informationPopupText = '';
        }, this.showInformationPopupDuration);
    }
}
